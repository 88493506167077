@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Poppins&family=Lato&display=swap');

h1, h2, h3 {
  font-family: 'Fira Sans', sans-serif;
}

h1 {
  line-height: 1.1em;
}

body {
  padding: 0;
  margin: 0;
  background-image: radial-gradient(ellipse farthest-corner at 100% 100%, var(--colour-primary) 0%, #294351 100%);
  background-attachment: fixed;
  color: var(--colour-text);

  text-align: center;
  font-family: 'Poppins', sans-serif;

  font-size: 18px;
  line-height: 1.5em;

  overflow-x: hidden;
}

@media only screen and (max-width: 600px) {
  body {
    line-height: 1.6em;
  }
}

a {
  text-decoration: none;
  color: white;
}

.content a, .styledAnchor {
  color: var(--colour-hyperlink);
  font-weight: bold;
  position: relative;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.1em;
    left: 0;
    width: 100%;
    height: 0.12em;
    background-color: var(--colour-hyperlink);
    opacity: 0;
    transition: opacity 200ms, transform 200ms, color 200ms;
    transform: scale(0);
    transform-origin: center;
  }

  &:focus::after, &:hover::after {
    opacity: 1;
    transform: scale(1);
    color: var(--colour-hyperlink-hover);
  }
}

button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

:root {
  --colour-text: #EEE;
  --colour-hyperlink: #0dc7ba;
  --colour-hyperlink-hover: #1ed6ca;
  --colour-primary: #353C6F;
  --colour-primary-darker: #282e5c;
  --colour-secondary: #881B36;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media print {
  .print-hidden {
    display: none !important;
  }
}